import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';
import Cardinal from '@/components/data-display/Cardinal';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { wrapWithErrorBoundary } from '@/components/feedback/utils/wrapWithErrorBoundary';

import { usePatientDashboardStatsLazyQuery } from '@/generated/graphql';

import { throttledGetTimeXHoursAgo } from '@/utils/time';

import { PatientDashboardStatsItem } from './PatientDashboardStatsItem';

export const PatientDashboardStats = () => {
  const router = useRouter();

  const currentUser = useCurrentUser();

  const patientId = currentUser?.patientId as string;

  const [oneHourAgoISO] = useState(throttledGetTimeXHoursAgo(1));

  const [fetchStats, { data, loading, error }] =
    usePatientDashboardStatsLazyQuery();

  useEffect(() => {
    if (patientId) {
      fetchStats({ variables: { patientId, startsAt: { gt: oneHourAgoISO } } });
    }
  }, [patientId]);

  if (error) throw error;

  if (loading) return <LoadingSpinner />;

  const {
    appointmentRequestsCount,
    appointmentsCount,
    bookingsCount,
    doctorsCount,
  } = data || {};

  return (
    <div className="flex flex-col gap-4 flex-1">
      <PatientDashboardStatsItem
        className="md:hidden flex-1"
        dataTestId="patient-dashboard-stats-item-upcoming"
        head="Appointment"
        body="Upcoming"
        count={bookingsCount}
        onClick={() => router.push('/appointments/upcoming')}
      />

      <PatientDashboardStatsItem
        className="hidden md:flex flex-1"
        dataTestId="patient-dashboard-stats-item-requests"
        head="Appointment"
        body="Requests"
        count={appointmentRequestsCount}
        onClick={() => router.push('/appointments/requests')}
      />

      <PatientDashboardStatsItem
        className="hidden md:flex flex-1"
        head="Appointment"
        body="Missed"
        count={appointmentsCount}
        onClick={() => router.push('/appointments/missed')}
        dataTestId="patient-dashboard-stats-item-missed"
      />

      <PatientDashboardStatsItem
        className="flex-1"
        head="My"
        body="Doctors"
        count={doctorsCount}
        onClick={() => router.push('/doctors')}
        dataTestId="patient-dashboard-stats-item-doctors"
      />

      <Link
        className="bg-surface flex border rounded-lg group hover:shadow-lg cursor-pointer transition-all flex-1"
        data-testid="link-go-to-medical-history"
        href="/medical-history"
      >
        <div className="flex items-center flex-1 py-4">
          <div className="pl-3">
            <Cardinal
              head="Go to"
              headClassName="font-normal text-xs"
              bodyVariant="h4"
              body="Medical History"
            />
          </div>
        </div>

        <div className="flex items-center pr-4 justify-end">{`->`}</div>
      </Link>
    </div>
  );
};

export const PatientDashboardStatsWithBoundary = wrapWithErrorBoundary(
  PatientDashboardStats
);
