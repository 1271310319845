import { useCurrentUser } from '@/auth/useCurrentUser';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { PatientDashboard } from '@/components/patient/PatientDashboard';

const PatientHome = () => {
  const { loading } = useCurrentUser();

  if (loading) return <LoadingSpinner />;

  return <PatientDashboard />;
};

export default PatientHome;
