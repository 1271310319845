import Cardinal, { CardinalProps } from '@/components/data-display/Cardinal';
import Text from '@/components/data-display/Text';

import { classNames } from '@/utils/classNames';

interface PatientDashboardStatsItemProps extends CardinalProps {
  count?: string | number | undefined | null;
  onClick: () => void;
  className?: string;
}

export const PatientDashboardStatsItem = ({
  head,
  body,
  count = 0,
  onClick,
  dataTestId,
  className,
}: PatientDashboardStatsItemProps) => {
  const numberCount = Number.isNaN(Number(count)) ? 0 : Number(count);
  const formattedCount = `${numberCount}`.padStart(2, '0');
  return (
    <div
      className={classNames(
        className,
        'bg-surface flex border rounded-lg group hover:shadow-lg cursor-pointer transition-all'
      )}
      role="button"
      tabIndex={0}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      onClick={onClick}
    >
      <div className="flex items-center flex-1 py-4">
        <div className="pl-3">
          <Cardinal
            head={head}
            headClassName="font-normal text-sm pb-1"
            bodyClassName="font-bold text-2xl"
            bodyAs="h5"
            body={body}
            dataTestId={`cardinal-${dataTestId}`}
          />
        </div>
      </div>

      <div className="bg-surface-secondary group-hover:bg-primary flex-1 flex items-center justify-center border rounded-lg -m-[1px] transition-all">
        <Text
          className="text-primary group-hover:text-white"
          dataTestId={`display-${dataTestId}-count`}
          variant="h2"
        >
          {formattedCount}
        </Text>
      </div>
    </div>
  );
};
