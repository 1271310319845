import { ComponentPropsWithoutRef } from 'react';

import Text from '@/components/data-display/Text';

interface NoResultsMessageProps extends ComponentPropsWithoutRef<'div'> {
  title?: string;
  message?: string;
}

export const NoResultsMessage = ({
  title = 'Nothing to show.',
  message = `We currently don't have any results to display for these parameters.`,
  ...rest
}: NoResultsMessageProps) => {
  return (
    <div
      className="p-4"
      data-testid={rest['data-testid'] || 'feedback-no-results'}
      {...rest}
    >
      <Text
        className="block"
        dataTestId={rest['data-testid'] || 'title-no-results'}
        variant="h3"
      >
        {title}
      </Text>
      <Text className="block" variant="caption">
        {message}
      </Text>
    </div>
  );
};
